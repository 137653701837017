<template>
  <div class="wrapper" id="marketDetail">
    <ctheader></ctheader>
    <div class="content" v-loading="loading" :element-loading-text="loading_text">
      <div class="base-info">
        <div class="top">
          <div>
            <span class="top-title">{{ data.market_name }}</span>
            <span>{{ data.market_code }}</span>
            <div>{{ data.region_data }} {{ data.addr_detail }}</div>
          </div>

          <div>
            <el-button class="top-right" type="primary" round v-if="data.lock_pos == 0" @click="lockPos(1)">锁秤</el-button>
            <el-button type="info" round plain v-else @click="lockPos(0)">解锁</el-button>
          </div>

        </div>
        <div class="info">
          <div class="field">
            <label>所属客户：</label>
            <span>{{ data.company_name }}</span>
          </div>
          <div class="field">
            <label>负责人：</label>
            <span>{{ data.contact }}</span>
          </div>
          <div class="field">
            <label>联系方式：</label>
            <span>{{ data.tel }}</span>
          </div>
        </div>
      </div>

      <div class="sub-title">市场管理</div>
      <div class="funs">
        <div class="fun" v-for="(row, index) in funs" @click="toFun(row.url, index)" :key="index">
          <span>{{ row.name }}</span>
        </div>
      </div>
    </div>
    <layer :show="layerShow" :title="funs[idxTem].name" :width="520" :btnLoadDisabled="btnLoadDisabled"
      :closeCallBack="closeLayer" :saveCallBack="saveLayer">
      <div class="form">
        <template v-if="isService">
          <div class="field">
            <label>服务名称：</label>
            <input type="text" class="w300" v-model="serveName" maxlength="20" />
          </div>
          <div class="field">
            <label>服务电话：</label>
            <input type="text" class="w300" v-model="servePhone" />
          </div>
        </template>
        <div class="field" v-else>
          <label :class="{ width138: channel_id + '' === '7' }">支付通道：</label>
          <select class="w300" v-model="channel_id" @change="cleanKey">
            <option v-for="row in payList" :value="row.id" :key="row.id">{{
              row.name
            }}</option>
          </select>
        </div>
        <template v-if="channel_id + '' === '3'">
          <div class="field">
            <label>机构ID：</label>
            <input type="text" class="w300" v-model="inst_no" maxlength="20" />
          </div>
          <div class="field">
            <label>key：</label>
            <input type="text" class="w300" v-model="key" />
          </div>
        </template>
        <template v-if="channel_id + '' === '7'">
          <div class="field">
            <label class="width138">机构商户号：</label>
            <input type="text" class="w300" v-model="instMid" />
          </div>
          <div class="field">
            <label class="width138">消息来源：</label>
            <input type="text" class="w300" v-model="msgSrc" />
          </div>
          <div class="field">
            <label class="width138">来源编号：</label>
            <input type="text" class="w300" v-model="msgSrcId" />
          </div>
          <div class="field">
            <label class="width138">测试环境MD5密钥：</label>
            <input type="text" class="w300" v-model="md5_secret" />
          </div>
          <div class="field">
            <label class="width138">appId：</label>
            <input type="text" class="w300" v-model="app_id" />
          </div>
          <div class="field">
            <label class="width138">appKey_D：</label>
            <input type="text" class="w300" v-model="app_secret" />
          </div>
        </template>
        <template v-if="channel_id + '' === '11'">
          <div class="field">
            <label>商户号：</label>
            <input type="text" class="w300" v-model="ccb.merchant_id" />
          </div>
          <div class="field">
            <label>柜台代码：</label>
            <input type="text" class="w300" v-model="ccb.pos_id" />
          </div>
          <div class="field">
            <label>分行号：</label>
            <input type="text" class="w300" v-model="ccb.branch_id" />
          </div>
          <div class="field">
            <label>公钥：</label>
            <textarea class="w300" v-model="ccb.pub_key"></textarea>
          </div>
        </template>
        <template v-if="channel_id + '' === '12'">
          <div class="field">
            <label>商户号：</label>
            <input type="text" class="w300" v-model="icbc.mer_id" />
          </div>
          <div class="field">
            <label>APP编号：</label>
            <input type="text" class="w300" v-model="icbc.app_id" />
          </div>
          <div class="field">
            <label>e生活档案编号：</label>
            <input type="text" class="w300" v-model="icbc.store_code" />
          </div>
          <div class="field">
            <label>私钥：</label>
            <textarea class="w300" v-model="icbc.private_key"></textarea>
          </div>
          <div class="field">
            <label>公钥：</label>
            <textarea class="w300" v-model="icbc.gway_public_key"></textarea>
          </div>
        </template>
        <!-- <div class="field" v-if="channel_id === '3'">
					<label>基础费率：</label>
					<input type="text" class="w150" v-model="key" @input="checkNum(key)" maxlength="3">
					<span class="unit">%</span>
					<span class="input-tip">不得低于0.3</span>
				</div> -->
      </div>
    </layer>
  </div>
</template>

<script>
/**
 * @module app/marketManage/marketDetail
 * @desc 传蔬农贸版 市场详情
 * @version 0.0.1
 * @author songkexin <songkexin@rongyi.com>
 * @date   2021-01-10
 * @copyright 2021
 */
import { mapGetters, mapActions } from "vuex";
import { apiPost } from "../../modules/utils";
// import ctheader from '@@/ctheader';
import ctheader from "../../components/ctheader";

// import layer from "@@/layers";
import layer from "../../components/layers";

// import * as URL from '_/URLs';
import * as URL from "../../modules/URLs";

import APIs from "../../modules/APIs";
import router from "@/router";
import {MarketDetailServe}from "./api"
const getters = mapGetters(["sideMenu"]);
const actions = mapActions(["setAlert"]);
export default {
  name: "marketdetail",
  computed: {
    ...getters,
  },
  components: { ctheader, layer },
  beforeRouteEnter(to, from, next) {
    console.log(to);
    let id = to.query.id;
    let getFciInfo = new Promise(function (resolve, reject) {
      apiPost({
        url: APIs.COMPANY,
        data: {
          action: "market.detail",
          market_id: id,
        },
        success(res) {
          resolve(res);
        },
        error(res) {
          reject(res);
        },
      });
    });
    let getPay = new Promise(function (resolve, reject) {
      apiPost({
        url: APIs.COMPANY,
        data: {
          action: "pay.channel.list",
        },
        success(res) {
          resolve(res);
        },
        error(res) {
          reject(res);
        },
      });
    });
    Promise.all([getFciInfo, getPay])
      .then((values) => {
        next((vm) => {
          vm.marketId = id;
          if (values[0].code === "0") {
            vm.data = values[0].data;
            // vm.funs[0].url = URL.CATEGORY + '/' + vm.marketId + '/' + vm.data.hq_code;
            // vm.funs[1].url = URL.COST_TYPE + '/' + vm.marketId + '/' + vm.data.hq_code;
          } else {
            vm.data = {};
          }
          if (values[1].code === "0") {
            vm.payList = values[1].data;
          } else {
            vm.payList = [];
          }
        });
      })
      .catch((values) => {
        next();
      });
  },
  data() {
    return {
      marketId: "", // 市场id
      agencyId: "", // 机构id
      data: {},
      funs: [
        {
          name: "支付设置",
          icon: "sdnm-commodity_4",
          show: true,
          url: "layer",
          msgNum: "",
          showMSg: false,
        },
        {
          name: "服务信息",
          icon: "sdnm-charge_bill",
          url: "service",
          show: true,
          msgNum: "",
          showMSg: false,
        },
        {
          name: "商品同步",
          icon: "sdnm-charge_bill",
          url: "copy",
          show: true,
          msgNum: "",
          showMSg: false,
        },
        {
          name: "商品图片同步",
          icon: "sdnm-charge_bill",
          url: "image",
          show: true,
          msgNum: "",
          showMSg: false,
        },
      ], // 功能块信息
      layerShow: false, // 是否显示支付设置框
      payList: [],
      channel_id: "",
      inst_no: "", // 支付3
      key: "", // 支付3
      btnLoadDisabled: false, // layer的disable标记
      instMid: "", // 银联支付配置参数
      msgSrc: "", // 银联支付配置参数
      msgSrcId: "", // 银联支付配置参数
      md5_secret: "", // 银联支付配置参数
      app_id: "", // 银联支付配置参数
      app_secret: "", // 银联支付配置参数
      ccb: {}, // 建行支付配置
      icbc: {}, // 工行支付配置
      serveName: "", // 服务名称
      servePhone: "", // 服务电话
      isService: false, // 服务设置标识
      idxTem: 0,
      hq_code: "",
      jdt: false, //进度条隐藏
      percentage: 0, //进度条值
      timeStart: "", //进度条计时
      loading: false,
      loading_text: "",
    };
  },
  methods: {
    ...actions,
    /**
     * 设置跳转
     * @author songkexin <songkexin@rongyi.com>
     * @date   2021-01-11
     */
    toFun(path, idx) {
      this.idxTem = idx;
      if (path === "layer") {
        let $this = this;
        apiPost({
          url: APIs.COMPANY,
          data: {
            action: "pay.current.channel",
            market_id: this.marketId,
            hq_code: this.data.hq_code,
          },
          success(res) {
            if (res.code === "0") {
              $this.channel_id = res.data.channel_id;
              if ($this.channel_id + "" === "3") {
                $this.inst_no = res.data.inst_no || "";
                $this.key = res.data.key || "";
              }
              if ($this.channel_id + "" === "7") {
                $this.instMid = res.data.instMid || "";
                $this.msgSrc = res.data.msgSrc || "";
                $this.msgSrcId = res.data.msgSrcId || "";
                $this.md5_secret = res.data.md5_secret || "";
                $this.app_id = res.data.app_id || "";
                $this.app_secret = res.data.app_secret || "";
              }
              if ($this.channel_id + "" === "11") {
                $this.ccb = res.data;
              }
              if ($this.channel_id + "" === "12") {
                $this.icbc = res.data;
              }
              $this.isService = false;
              $this.layerShow = true;
            } else {
              $this.setAlert = { msg: res.msg, type: false };
            }
          },
          error(res) { },
        });
      } else if (path === "service") {
        let $this = this;
        apiPost({
          url: APIs.POS,
          data: {
            action: "pos.display.setting",
            market_id: this.marketId,
            hq_code: this.data.hq_code,
          },
          success(res) {
            if (res.code === "0") {
              $this.serveName = res.data.name || "";
              $this.servePhone = res.data.tel || "";
              $this.isService = true;
              $this.layerShow = true;
            } else {
              $this.setAlert = { msg: res.msg, type: false };
            }
          },
          error(res) { },
        });
      } else if (path === "copy") {
        // router.push(path);
        router.push(
          URL.MARKET_DETAIL_COPY +
          "?id=" +
          this.marketId +
          "&hq_code=" +
          this.data.hq_code
        );
      } else if (path === "image") {
        console.log("12121");
        this.loading = true;
        this.percentage = 0;
        this.loading_text = "正在同步中";

        var timeStart = setInterval(() => {
          if (this.percentage < 79) {
            this.percentage = this.percentage + 20;
            this.loading_text = "正在同步中" + this.percentage + "%";
          } else {
            this.percentage = 99;
            this.loading_text = "正在同步中" + 99 + "%";
          }
        }, 1000);
        var $this = this;
        const params = {
          action: "market.product.copy",
          market_id: $this.marketId,
        };
        apiPost({
          url: APIs.COMPANY,
          data: params,
          success(res) {
            console.log(res);
            if (res.code === "0") {
              $this.loading = false;
              clearInterval(timeStart);
              $this.$message({
                message: res.msg,
                type: "success",
              });
            } else {
              $this.loading = false;
              $this.setAlert({ msg: res.msg });
            }
          },
          error(res) {
            $this.setAlert({ msg: "获取数据失败" });
          },
        });
        setTimeout(() => {
          // this.percentage = 100;
          // this.jdt = false;
          // this.loading = false;
          clearInterval(timeStart);
        }, 5000);
      } else {
        router.push(path);
      }
    },
    checkNum(val) {
      let reg = /(?:(\d{1,9})(\.\d{0,2})?)/;
      let result = reg.exec(val);
      this.key = result !== null ? result[0] : "";
    },
    /**
     * 关闭支付设置
     * @author songkexin <songkexin@rongyi.com>
     * @date   2021-01-11
     */
    closeLayer() {
      this.layerShow = false;
    },
    /**
     * 切换支付通道时清空key值
     * @author songkexin <songkexin@rongyi.com>
     * @date   2021-01-11
     */
    cleanKey() {
      this.inst_no = "";
      this.key = "";
    },
    /**
     * 保存支付设置
     * @author songkexin <songkexin@rongyi.com>
     * @date   2021-01-11
     */
    saveLayer() {
      let $this = this;
      if (!this.isService) {
        let keyInfo = {};
        if (this.channel_id === "") {
          this.setAlert({ msg: "请选择支付通道", type: false });
          return false;
        }
        if (this.channel_id + "" === "3") {
          if (this.inst_no === "") {
            this.setAlert({ msg: "请填写机构号", type: false });
            return false;
          }
          if (this.key === "") {
            this.setAlert({ msg: "请填写费率", type: false });
            return false;
          }
          // if(parseFloat(this.key) < 0.3){
          // 	this.setAlert({msg: '费率不得低于0.3', type: false});
          // 	return false;
          // }
          keyInfo = { inst_no: this.inst_no, key: this.key };
        }
        if (this.channel_id + "" === "7") {
          if (this.instMid === "") {
            this.setAlert({ msg: "请填写机构商户号", type: false });
            return false;
          }
          if (this.msgSrc === "") {
            this.setAlert({ msg: "请填写信息来源", type: false });
            return false;
          }
          if (this.msgSrcId === "") {
            this.setAlert({ msg: "请填写来源编号", type: false });
            return false;
          }
          if (this.md5_secret === "") {
            this.setAlert({ msg: "请填写测试环境MD5密钥", type: false });
            return false;
          }
          if (this.app_id === "") {
            this.setAlert({ msg: "请填写appId", type: false });
            return false;
          }
          if (this.app_secret === "") {
            this.setAlert({ msg: "请填写appKey_D", type: false });
            return false;
          }
          keyInfo = {
            instMid: this.instMid,
            msgSrc: this.msgSrc,
            msgSrcId: this.msgSrcId,
            md5_secret: this.md5_secret,
            app_id: this.app_id,
            app_secret: this.app_secret,
          };
        }
        if (this.channel_id + "" === "11") {
          if (!this.ccb.merchant_id) {
            this.setAlert({ msg: "请填写商户号", type: false });
            return false;
          }
          if (!this.ccb.pos_id) {
            this.setAlert({ msg: "请填写柜台代码", type: false });
            return false;
          }
          if (!this.ccb.branch_id) {
            this.setAlert({ msg: "请填写分行号", type: false });
            return false;
          }
          if (!this.ccb.pub_key) {
            this.setAlert({ msg: "请填写公钥", type: false });
            return false;
          }
          keyInfo = this.ccb;
        }
        if (this.channel_id + "" === "12") {
          if (!this.icbc.mer_id) {
            this.setAlert({ msg: "请填写商户号", type: false });
            return false;
          }
          if (!this.icbc.app_id) {
            this.setAlert({ msg: "请填写APP编号", type: false });
            return false;
          }
          if (!this.icbc.store_code) {
            this.setAlert({ msg: "请填写e生活档案编号", type: false });
            return false;
          }
          if (!this.icbc.private_key) {
            this.setAlert({ msg: "请填写私钥", type: false });
            return false;
          }
          if (!this.icbc.gway_public_key) {
            this.setAlert({ msg: "请填写公钥", type: false });
            return false;
          }
          keyInfo = this.icbc;
        }
        this.btnLoadDisabled = true;
        apiPost({
          url: APIs.COMPANY,
          data: {
            action: "pay.channel.set",
            market_id: this.marketId,
            hq_code: this.data.hq_code,
            channel_id: this.channel_id,
            value: keyInfo,
          },
          success(res) {
            $this.btnLoadDisabled = false;
            if (res.code === "0") {
              $this.setAlert({
                msg: res.msg,
                type: true,
              });
              $this.layerShow = false;
            } else {
              $this.setAlert({
                msg: res.msg,
                type: false,
              });
            }
          },
          error(res) {
            $this.btnLoadDisabled = false;
            $this.setAlert({
              msg: res.msg,
              type: false,
            });
          },
        });
      } else {
        this.btnLoadDisabled = true;
        apiPost({
          url: APIs.POS,
          data: {
            action: "save.display.setting",
            market_id: this.marketId,
            hq_code: this.data.hq_code,
            name: this.serveName,
            tel: this.servePhone,
          },
          success(res) {
            $this.btnLoadDisabled = false;
            if (res.code === "0") {
              $this.setAlert({
                msg: res.msg,
                type: true,
              });
              $this.layerShow = false;
            } else {
              $this.setAlert({
                msg: res.msg,
                type: false,
              });
            }
          },
          error(res) {
            $this.btnLoadDisabled = false;
            $this.setAlert({
              msg: res.msg,
              type: false,
            });
          },
        });
      }
    },

    async lockPos(val) {
      console.log(val,"1212")
      await this.$confirm(val ==1?"确认锁秤么？":"确认解锁么？", "提示", {
        confirmButtonText: "确定",                                 
        cancelButtonText: "取消",
        type: "warning",
      });
      const { msg, code } = await MarketDetailServe({
        action: "market.lock.change",
        market_id:  this.marketId,
        lock_pos:val
      });
      if (code == 0) {
       this.$message.success(msg)
       this.getMarketDetail()
   
      }
    },
    async getMarketDetail(){
      const {code,data} = await MarketDetailServe({
        action: "market.detail",
        market_id: this.marketId
      })
      if(code == 0){
        this.data = {
          ...this.data,
          ...data
        }
      }
    }
  },
};
</script>

<style lang="less">
@import url("../../less/common.less");

#marketDetail {
  .mgl20 {
    .mgl(32px) !important;
    .pos-r();

    &:after {
      content: "";
      .wh(1px, 8px);
      position: absolute;
      right: 70px;
      top: 2px;
      .bgc(#aeb5bf);
    }
  }

  .form {
    .pd(31px, 0, 0, 32px);

    .field {
      .mgb(10px);

      >label {
        width: 110px;
        .ta-rt();
        .pdl(10px);
        .pdr(0px);
      }

      .unit {
        color: #a0b9dc;
        display: inline-block;
        text-align: center;
        cursor: pointer;
        margin-left: -28px;
      }

      .input-tip {
        .fc(12px, #aeb5bf);
        .mgl(15px);
      }
    }
  }

  .import-div {
    .pd(44px);
    .ta-c();
  }

  .import {
    .dib();
    .fc(18px, #ffffff);
    .pd(8px);
    .bgc(#257873);
    .brd(1px, solid, #257873);
    .brdr(4px);
    .mgr(20px);
    .va-t();
  }

  .width138 {
    width: 138px !important;
  }
}

#marketDetail .content {
  background-color: #eceff3;
  box-shadow: none;
  overflow-y: auto;
  .pd(0);
  font-size: 0;
}

#marketDetail .base-info {
  height: 168px;
  width: 100%;
  .dib();
  .bgc(#ffffff);
  box-shadow: 0 0 10px 0 #e0e3e8;
  .brdr(4px);
  .pd(24px);
  .pos-r();

  .top {
    display: flex;
    justify-content: space-between;
    .mgt(10px);
    .mgb(20px);

    span {
      display: inline-block;
      .fc(12px, #aeb5bf);

      &.top-title {
        .fc(18px, #253650);
        margin-right: 15px;
      }
    }

    div {
      .fc(12px, #253650);
    }
    .top-right{
      span{
        .fc(12px, #fff);
      }
      
    }
  }


  .info {
    .field {
      .dib();
      width: 33%;
      .mgb(10px);
      .hlh(15px, 15px);

      >label {
        .fc(12px, #aeb5bf);
      }

      >span {
        .fc(12px, #253650);
      }
    }
  }
}

#marketDetail .sub-title {
  .fc(18px, #a0b9db);
  .mgt(30px);
  .mgb(16px);
}

#marketDetail .funs {
  .fun {
    .dib();
    .wh(180px, 120px);
    .bgc(#ffffff);
    .brdr(4px);
    .ta-c();
    .mgr(16px);
    .mgb(25px);
    .cs();
    .pos-r();

    >i {
      .fc(40px, #a0b9db);
      .db();
      .mgt(27px);
      .mgb(9px);
    }

    >span {
      .fc(16px, #525e6e);
      line-height: 120px;
    }

    &:hover {
      .bgc(#257873);

      >i,
      >span {
        color: #ffffff;
      }
    }

    >div {
      position: absolute;
      height: 16px;
      .pd(1px, 5px, 1px, 4px);
      .bgc(#f84445);
      .fc(10px, #ffffff);
      .brdr(4px);
      right: 59px;
      top: 19px;
      line-height: 14px;
      -webkit-transform-origin-x: 0;
      -webkit-transform: scale(0.9);
    }
  }
}
</style>
