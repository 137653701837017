import request from "@/utils/request";

//锁秤
export function MarketDetailServe(data) {
  return request({
    url: "/api/company",
    method: "post",
    data,
  });
}

